<template>
  <b-dropdown variant="outline-secondary" class="d-flex" ref="dropdown">
    <template slot="button-content">
      <i class="fa fa-user-friends"></i>
      {{
        allCount === 0
          ? $t('search-tab.who-is-traveling')
          : `${allCount} ${$t('search-result.persons-selected')}`
      }}
    </template>
    <b-card href="#" class="border-0 pb-0">
      <b-dropdown-item v-if="topButton"
        ><h5 class="text-center">
          <b-button
            variant="outline-primary"
            class="rounded mb-3 w-100"
            :disabled="!fullAgeChildren"
            @click="selectPaxes"
            >{{ $t('search-tab.choose-count-select') }}</b-button
          >
        </h5></b-dropdown-item
      >
      <div class="form-group row">
        <label class="col-sm-4 col-form-label p-2">
          {{ $t('search-tab.adult') }}
          <span class="text-muted small">(12+)</span>
        </label>
        <div class="col-sm-8 p-1">
          <b-form-spinbutton
            class="rounded"
            v-model="adult"
            min="0"
            max="9"
            step="1"
            size="lg"
          ></b-form-spinbutton>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label p-2">
          {{ $t('search-tab.child') }}
          <p class="text-muted small">(2-12)</p>
        </label>
        <div class="col-sm-8 p-1">
          <b-form-spinbutton
            class="rounded"
            v-model="child"
            min="0"
            max="9"
            step="1"
            size="lg"
          ></b-form-spinbutton>
          <PaxAge
            v-for="i in child"
            :key="i"
            :index="i"
            :selected="listAgeChildren[i]"
            :options="rangeAgeChildren"
            @change="updateAgeChildren"
          ></PaxAge>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label p-2">
          {{ $t('search-tab.infant') }}
          <p class="text-muted small">(0-2)</p>
        </label>
        <div class="col-sm-8 p-1">
          <b-form-spinbutton
            class="rounded"
            v-model="infant"
            min="0"
            max="9"
            step="1"
            size="lg"
          ></b-form-spinbutton>
          <PaxAge
            v-for="i in infant"
            :key="i"
            :index="i"
            :selected="listAgeInfants[i]"
            :options="rangeAgeInfants"
            @change="updateAgeInfants"
          ></PaxAge>
        </div>
      </div>
      <b-dropdown-item v-if="!topButton"
        ><h5 class="text-center">
          <b-button
            variant="outline-primary"
            class="w-100 rounded"
            :disabled="!fullAgeChildren"
            @click="selectPaxes"
            >{{ $t('search-tab.choose-count-select') }}</b-button
          >
        </h5></b-dropdown-item
      >
    </b-card>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BCard,
  BFormSpinbutton,
  BDropdownItem,
  BButton,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PaxInformation',
  components: {
    BDropdown,
    BCard,
    BFormSpinbutton,
    BDropdownItem,
    BButton,
    PaxAge: () => import('@/components/atoms/PaxAge'),
  },
  props: {
    dropdownShow: {
      type: Boolean,
      default: false,
    },
    topButton: {
      type: Boolean,
      default: true,
    },
    tourists: {
      type: Object,
      default: () => ({
        adult: 0,
        child: 0,
        infant: 0,
        listAgeChildren: [],
        listAgeInfants: [],
      }),
    },
  },
  data() {
    return {
      query: '',
      listAgeChildren: [],
      listAgeInfants: [],

      adult: 0,
      child: 0,
      infant: 0,

      fullAgeChildren: false,
    };
  },
  watch: {
    adult(value, old) {
      if (value === old) return;
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
    },
    child(value, old) {
      if (value === old) return;
      this.updatePaxesState();
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
      this.listAgeChildren.splice(this.child + 1);
    },
    infant(value, old) {
      if (value === old) return;
      this.updatePaxesState();
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
      this.listAgeInfants.splice(this.infant + 1);
    },
    tourists(value) {
      this.adult = Number(value.adult);
      this.child = Number(value.child);
      this.infant = Number(value.infant);
      this.listAgeChildren = value.listAgeChildren;
      this.listAgeInfants = value.listAgeInfants;
    },
    dropdownShow() {
      if (this.dropdownShow) {
        this.$refs.dropdown.show(true);
      } else {
        this.$refs.dropdown.hide(true);
      }
    },
  },
  computed: {
    ...mapGetters({
      rangeAgeChildren: 'GET_RANGE_AGE_CHILDREN',
      rangeAgeInfants: 'GET_RANGE_AGE_INFANTS',
    }),
    allCount() {
      return this.adult + this.child + this.infant;
    },
  },
  created() {
    this.adult = Number(this.tourists.adult);
    this.child = Number(this.tourists.child);
    this.infant = Number(this.tourists.infant);
    this.listAgeChildren = this.tourists.listAgeChildren;
    this.listAgeInfants = this.tourists.listAgeInfants;
    this.updatePaxesState();
  },
  methods: {
    updateAgeChildren(pObj) {
      this.listAgeChildren[pObj.index] = pObj.value;
      this.changedAgeState();
    },
    updateAgeInfants(pObj) {
      this.listAgeInfants[pObj.index] = pObj.value;
      this.changedAgeState();
    },
    updatePaxesState() {
      let ageState = true;
      for (let i = 0; i < this.child; i += 1) {
        if (this.listAgeChildren[i + 1] === undefined) {
          ageState = false;
          break;
        }
      }
      for (let i = 0; i < this.infant; i += 1) {
        if (this.listAgeInfants[i + 1] === undefined) {
          ageState = false;
          break;
        }
      }
      this.fullAgeChildren = ageState;
    },
    changedAgeState() {
      this.updatePaxesState();
      this.$emit('changedAgeState', {
        full: this.fullAgeChildren,
        listAgeChildren: this.listAgeChildren,
        listAgeInfants: this.listAgeInfants,
      });
    },
    selectPaxes() {
      this.$emit('completedPaxes', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
        listAgeChildren: this.listAgeChildren,
        listAgeInfants: this.listAgeInfants,
      });
    },
  },
};
</script>

<style>
.filter-tab .dropdown .btn.dropdown-toggle {
  background-color: #fff;
  color: #212529;
  border-color: rgba(0, 0, 0, 0.2);
  font-size: 1rem;
}
.text-muted {
  direction: ltr;
}

.chooseroom_area .b-form-spinbutton output > bdi {
  font-size: 20px;
  min-width: 1em;
}

.dropdown-menu {
  /* width: 100%; */
  min-width: 230px;
}
</style>
